import Image from "next/legacy/image";
import hddLogo from "./assets/images/logo-huge-ding-dong.svg";
import { useSession, signIn, signOut } from "next-auth/react";
import classes from "./DingDongPageComponent.module.scss";
import Link from "next/link";

export default function TopBar(props) {
  const { data: session, status } = useSession();

  const login = (e) => {
    if (status === "authenticated") {
      signOut();
    }
    
    if (status === "unauthenticated") {
      signIn("cognito", {
        callbackUrl: `${window.location.origin}${window.location.pathname}?signed=true`,
      });
    }
  };

  return (
    <div className={classes.topBar}>
      <Link href="/app" className={classes.logo}>
        <Image
          src={hddLogo}
          alt="Huge Ding Dong Logo"
          layout="fixed"
          width={140}
          height={22}
          priority
        />
      </Link>
      <div className={classes.userInfo}>
        {status === "authenticated" && (
          <span>
            Logged in as:{" "}
            <Link href={`/app/profile/${session.user.id}`}>
              {session.user.name}
            </Link>
          </span>
        )}
        <button className={classes.loginBtn} onClick={login}>
          {status === "authenticated" ? "Log out" : "Log in"}
        </button>
      </div>
    </div>
  );
}
