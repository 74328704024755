import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popupState: "", // add | edit | clone | ""
  addingDate: "", // new Date
  editingEntry: null, // whole entry object {id:'', duration: '' ...} | null
  cloningEntry: null, // whole entry object {id:'', duration: '' ...} | null
  user: {
    id: null,
  },
};

export const hddSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setPopupState: (state, action) => {
      state.popupState = action.payload;
    },
    setAddingDate: (state, action) => {
      state.addingDate = action.payload;
    },
    setEditingEntry: (state, action) => {
      state.editingEntry = action.payload;
    },
    setCloningEntry: (state, action) => {
      state.cloningEntry = action.payload;
    },
    setUser: (state, action) => {
      state.user = {...action.payload};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPopupState,
  setUser,
  setAddingDate,
  setEditingEntry,
  setCloningEntry,
} = hddSlice.actions;
export default hddSlice.reducer;
