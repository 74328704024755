import { useRouter } from "next/router";
import { store } from "/store/store";
import { Provider } from "react-redux";
import "../styles/globals.scss";

import Head from "next/head";
import Layout from "../components/layout/Layout";
import { SessionProvider } from "next-auth/react";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <SessionProvider session={session}>
      <Provider store={store}>
        <Head>
          <>
            <link rel="apple-touch-icon" sizes="152x152" href="/hdd-apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/hdd-favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/hdd-favicon-16x16.png" />
          </>
        </Head>

        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </SessionProvider>
  );
}

export default MyApp;
