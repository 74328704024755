import TopBar from "../hdd/TopBar";
import { useSession } from "next-auth/react";

const Layout = (props) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default Layout;
